import React from 'react';
import './App.css';
import MyForm from './Form';


function App() {

  const hStyle: CSSProperties = {
    marginBottom:0,
    marginTop:0,
    textAlign: 'left',
    fontWeight: 'normal',
  };

  return (
    <div className="App">
      <header className="App-header">
        <div id="top" className="App-top">
          <h1 style={hStyle}>Precode &#123;&#125; Labs</h1>
          <h4 style={hStyle}>security, privacy, trust</h4>
        </div>
        <div className="App-bottom">
          <h6 style={hStyle}>San Francisco | Silicon Valley
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; 
            <a href="#contactus">Contact Us</a>
            &nbsp; &nbsp; 
          </h6>
        </div>
      </header>
      <MyForm />
    </div>
  );
}

export default App;
